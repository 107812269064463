
<template>
    <section>
        <Header/>

        <section class="main">
          <Feedback />
        </section>

          <section class="topBar" style="background-color:#1B355E;margin-top:0;color:white;text-shadow: 0px 3px 6px #00000091;">
            <h1 style="text-align:left;margin-left:10%;font-size:4vw;padding:10px 0px 10px 0px;">{{content[this.setLang].pagetitle}}</h1>
          </section>
          
          
          <section class="introText">
            <p>{{content[this.setLang].intro1}}</p> 
            <p>{{content[this.setLang].intro2}}</p>
            <p>{{content[this.setLang].intro3}}</p>

            <div v-if="setLang == 'fr'">
              <div style="border:1px solid #E7401C;;text-align:center;">
                <img class="img-fluid m-5" style="width:90%;" src="/img/overview_fr.png" />
              </div>
              <div style="width:20%;margin-top:-25px;margin-left:71%;background:white;">
                <a href="/img/overview_fr.png" download="ODSS_Overview_Infographic.png">
                  <p class="downloadBtn">{{content[this.setLang].download_text}}</p>
                </a>
              </div>
            </div>
            <div v-else>
              <div style="border:1px solid #E7401C;;text-align:center;">
                <img class="img-fluid m-5" style="width:90%;" src="/img/overview.png" />
              </div>
              <div style="width:20%;margin-top:-25px;margin-left:71%;background:white;">
                <a href="/img/overview.png" download="ODSS_Overview_Infographic.png">
                  <p class="downloadBtn">{{content[this.setLang].download_text}}</p>
                </a>
              </div>
            </div>

            <br/><br/><br/><br/>

            <div role="tablist">
              <b-card no-body class="mb-1 accordion_background_color" >
                <b-card-header header-tag="header" class="p-1 border-0 accordion_background_color" role="tab" >
                  <b-button block v-b-toggle.acc_what class="accordion_header_button font-weight-bold" style="background-color:#E7401C;">
                    {{content[this.setLang].acc1_title}}
                    <span class="when-opened">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/resource_minus.svg" alt="overview minus" />
                    </span>
                    <span class="when-closed">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/resource_plus.svg" alt="overview minus" />
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse id="acc_what" accordion="my-accordion" role="tabpanel" class="accordion_collapse_body">
                  <b-card-body>
                    <b-card-text>{{content[this.setLang].acc1_text1}}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1 accordion_background_color">
                <b-card-header header-tag="header" class="p-1 boder-0 accordion_background_color" role="tab">
                  <b-button block v-b-toggle.acc_how class="accordion_header_button font-weight-bold" style="background-color:#E7401C;">
                    {{content[this.setLang].acc2_title}}
                    <span class="when-opened">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/resource_minus.svg" alt="overview minus" />
                    </span>
                    <span class="when-closed">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/resource_plus.svg" alt="overview minus" />
                    </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="acc_how" accordion="my-accordion" role="tabpanel" class="accordion_collapse_body">
                  <b-card-body>
                    <b-card-text>{{content[this.setLang].acc2_text1}}</b-card-text>
                    <b-card-text>{{content[this.setLang].acc2_text2}}</b-card-text>
                    <b-card-text>
                      <a v-if="setLang == 'fr'" target="_blank" href="https://www.odsp-ocrc.ca/fr/vue-densemble-des-donnees/">{{content[this.setLang].acc2_text3}}</a>
                      <a v-else target="_blank" href="https://www.odsp-ocrc.ca/odss-data/">{{content[this.setLang].acc2_text3}}</a>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1 accordion_background_color">
                <b-card-header header-tag="header" class="p-1 border-0 accordion_background_color" role="tab">
                  <b-button block v-b-toggle.acc_which class="accordion_header_button font-weight-bold" style="background-color:#E7401C;">
                    {{content[this.setLang].acc3_title}}
                    <span class="when-opened">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/resource_minus.svg" alt="overview minus" />
                    </span>
                    <span class="when-closed">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/resource_plus.svg" alt="overview minus" />
                    </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="acc_which" accordion="my-accordion" role="tabpanel" class="accordion_collapse_body">
                  <b-card-body>
                    <b-card-text><b>{{content[this.setLang].acc3_sub1}}</b></b-card-text>
                    <b-card-text>{{content[this.setLang].acc3_text1}}</b-card-text>

                    <b-card-text><b>{{content[this.setLang].acc3_sub2}}</b></b-card-text>
                    <b-card-text>{{content[this.setLang].acc3_text2}}</b-card-text>

                    <b-card-text><b>{{content[this.setLang].acc3_sub3}}</b></b-card-text>
                    <b-card-text>{{content[this.setLang].acc3_text3}}</b-card-text>

                    <b-card-text><b>{{content[this.setLang].acc3_sub4}}</b></b-card-text>
                    <b-card-text>{{content[this.setLang].acc3_text4}}</b-card-text>

                    <b-card-text><b>{{content[this.setLang].acc3_sub5}}</b></b-card-text>
                    <b-card-text>{{content[this.setLang].acc3_text5}}</b-card-text>

                    <b-card-text><b>{{content[this.setLang].acc3_sub6}}</b></b-card-text>
                    <b-card-text>{{content[this.setLang].acc3_text6}}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1 accordion_background_color">
                <b-card-header header-tag="header" class="p-1 border-0 accordion_background_color" role="tab">
                  <b-button block v-b-toggle.acc_which2 class="accordion_header_button font-weight-bold" style="background-color:#E7401C;">
                    {{content[this.setLang].acc4_title}}
                    <span class="when-opened">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/resource_minus.svg" alt="overview minus" />
                    </span>
                    <span class="when-closed">
                      <img class="img-fluid" style="float:right;font-size:10px;" src="../assets/resource_plus.svg" alt="overview minus" />
                    </span>
                    </b-button>
                </b-card-header>
                <b-collapse id="acc_which2" accordion="my-accordion" role="tabpanel" class="accordion_collapse_body">
                  <b-card-body>
                    <b-card-text>{{content[this.setLang].acc4_text1}}</b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>

          </section>

        <Footer/>
    </section>
</template>

<script>
// Imports
import Vue from 'vue'
import VueHead from 'vue-head'
import Header from './Header.vue'
import Footer from './Footer.vue'
import Feedback from '../components/feedback.vue'

Vue.use(VueHead)

export default {
  components: {
    Header,
    Footer,
    Feedback
  },
  data() {
    return {
     abouttext : '',
     content: {
       en: {
        pagetitle: "Overview",
        infographic: "/img/overview.png",
        download_text: "Download Infographic",
        intro1: "This website allows users to view key results and explore data generated by the Occupational Disease Surveillance System (ODSS). The ODSS provides information about which industry and occupation groups in Ontario are at increased risk of disease. The website is currently organized by industry sector, and information on occupational exposure and prevention will be added in 2021. Users can view summary infographics to see key sector findings from the ODSS and can use the Data Tool to explore risks of diseases among workers in different industry sectors.",
        intro2: "The ODSS generates estimates of comparative risk, not absolute risk, which allows users to compare the risk of a disease in a particular group with all other workers in the ODSS.",
        intro3: "Website users should be aware that patterns of risk reported among workers in the ODSS can reflect workplace exposures from many decades earlier, which may have since been eliminated or reduced. The ODSS also captures a worker’s industry of employment and occupation at a single point in time, and does not take into account jobs changes over time. Information generated by the ODSS is useful for detecting disease risks among groups of workers by industry and/or occupation, but interpretation of these risks should consider changes in exposure, changes in the Ontario workforce, and potential influences of non-occupational risk factors including behaviours like smoking that are not available in the data sources used to create the ODSS.",
        acc1_title: "What is the ODSS?",
        acc1_text1: "The Occupational Disease Surveillance System (ODSS) is a unique system that can identify and monitor trends in work-related disease in Ontario. This system was created in 2016 by linking existing provincial health databases with job information in order to study occupational disease and inform prevention activities. The ODSS identifies at-risk groups of workers, and potential hazardous exposures, within the workplace. The ODSS can be used to investigate numerous cancers and non-malignant diseases across hundreds of occupation and industry groups. Findings from the ODSS contribute to our understanding of occupational disease in Ontario, and can support changes to public health practice. ",
        acc2_title: "How was the ODSS created?",
        acc2_text1: "The ODSS consists of 2.2 million Ontario workers with an accepted lost time compensation claim between 1983 and 2014. A worker who is compensated for a workplace-related illness or injury that resulted in missing work is captured in the provincial compensation board’s database. This database captures job information such as a worker’s occupation and industry of work associated with the claim. The ODSS was created by linking this source of job information to different health databases (see Infographic).",
        acc2_text2: "Disease diagnoses are routinely collected in various provincial health databases when an individual interacts with the healthcare system, for instance when visiting a physician, hospital, or cancer treatment centre. To identify disease cases, these 2.2 million workers were linked to tumour registry data, hospital records, ambulatory care records, and physician billing records. By combining occupation and industry from lost time compensation claims data with disease information from health databases, the ODSS provides an efficient novel approach to study work-related diseases.",
        acc2_text3: "Learn about the methods here.",
        acc3_title: "Which data sources are in the ODSS?",
        acc3_sub1: "Workplace Safety and Insurance Board’s (WSIB) Lost Time Claims Database",
        acc3_text1: "The WSIB accepted lost time claims database contains information related to compensation claims for work-related injuries and illnesses. These records include the occupation and industry of the workers, and the ODSS links this information to health databases to examine trends in work-related disease.",
        acc3_sub2: "RPDB – Registered Persons Database",
        acc3_text2: "The Ontario Health Insurance Plan’s (OHIP) Registered Persons Database (RPDB) contains information about individuals who are registered for insured health services in Ontario through OHIP. Each registered person is assigned a unique 10-digit health insurance number (HIN). In order to link the WSIB lost time records to other administrative health databases, WSIB records were first linked to the RPDB to retrieve the HIN, the common identifier between these data sources, required for subsequent linkages.",
        acc3_sub3: "OCR – Ontario Cancer Registry",
        acc3_text3: "This database contains information about all newly diagnosed cancer cases in Ontario, excluding non-melanoma skin cancers. This database is used to identify cases of cancer in the ODSS.",
        acc3_sub4: "Ontario Health Insurance Plan (OHIP) eClaims Database",
        acc3_text4: "This database contains records submitted by health care providers (primarily physicians) from independent health facilities, primary care, academic health science centres and hospitals for billing and accounting purposes. This database is used to identify cases of non-malignant disease in the ODSS.",
        acc3_sub5: "NACRS – National Ambulatory Care Reporting System",
        acc3_text5: "This system contains data for ambulatory care visits including day surgery, outpatient and community-based clinics, and emergency departments. This database is used to identify cases of non-malignant disease in the ODSS.",
        acc3_sub6: "DAD – Discharge Abstract Database",
        acc3_text6: "This database contains discharge information from inpatient hospitalizations. This database is used to identify cases of non-malignant disease in the ODSS.",
        acc4_title: "Which diseases are studied in the ODSS?",
        acc4_text1: "The ODSS is currently being used to examine associations between occupation and industry and 28 cancer sites and 9 non-malignant health outcomes. Standard tumour registry case definitions are used for cancer. The case definitions for non-malignant diseases were developed with an advisory committee of occupational physicians and other experts, and include relevant diagnostic codes, the age range during which a worker is considered at-risk, the number of patient encounters required to be considered a case, and the data sources (i.e. OCR, DAD, NACRS, OHIP) used to identify cases."

       },
       fr: {
        pagetitle: "Aperçu",
        infographic: "/img/overview_fr.png",
        download_text: "Télécharger l'infographie",
        intro1: "Ce site Web permet aux utilisateurs de voir les principaux résultats et d'explorer les données générées par le Système de surveillance des maladies professionnelles (SSMP). Le SSMP fournit de l'information sur les secteurs et les groupes professionnels de l'Ontario qui sont plus vulnérables à la maladie. Le site Web est actuellement organisé par secteur d'industrie, et de l'information sur l'exposition et la prévention en milieu de travail sera ajoutée en 2021. Les utilisateurs peuvent voir des infographies sommaires sur les principaux résultats par secteur et utiliser l'outil de données pour explorer les risques chez les travailleurs dans différents secteurs d'industrie.",
        intro2: "Le SSMP génère des estimations du risque comparatif et non pas du risque absolu, qui permettent aux utilisateurs de comparer le risque d'une maladie dans un groupe en particulier au risque des autres travailleurs dans le SSMP.",
        intro3: "Les utilisateurs du site Web doivent savoir que les tendances de risque signalées chez les travailleurs dans le SSMP peuvent indiquer une exposition antérieure de plusieurs décennies qui, depuis, peut avoir été éliminée ou diminuée. Le SSMP indique aussi des données sur le secteur d'emploi et la profession d'un travailleur à un moment donné, et ne tient pas compte des changements d'emploi au fil du temps. L'information générée par le SSMP est utile pour détecter les risques de maladie dans des groupes de travailleurs par industrie et/ou profession. Toutefois, l'interprétation de ces risques devraient tenir compte de la variation de l'exposition, des changements dans la main-d'oeuvre de l'Ontario et de l'influence potentielle des risques non associés à la profession, notamment les comportements comme le tabagisme, qui ne sont pas disponibles dans les sources de données utilisées pour créer le SSMP.",
        acc1_title: "Qu’est-ce que le SSMP?",
        acc1_text1: "Le Système de surveillance des maladies professionnelles (SSMP) est un système unique qui permet de déterminer et de surveiller les tendances relatives aux maladies professionnelles en Ontario. On a créé ce système en 2016 en combinant les bases de données provinciales sur la santé existantes avec des renseignements sur les emplois pour étudier les maladies professionnelles et orienter les activités de prévention. Le SSMP détermine les groupes de travailleurs qui présentent un risque et les expositions dangereuses possibles dans le milieu de travail. Le SSMP peut être utilisé pour étudier un grand nombre de cancers et de maladies non malignes parmi des centaines de groupes professionnels et industriels. Les résultats du SSMP nous permettent de mieux comprendre les maladies professionnelles en Ontario et peuvent favoriser les changements de pratique en matière de santé publique. ",
        acc2_title: "Comment le SSMP a-t-il été créé?",
        acc2_text1: "Le SSMP comprend les données de 2,2 millions de travailleurs de l’Ontario pour lesquels une demande de prestations pour interruption de travail a été acceptée entre 1983 et 2014. Les données relatives à un travailleur indemnisé pour une maladie professionnelle ou un accident de travail ayant entraîné une interruption de travail sont enregistrées dans la base de données de la commission provinciale des accidents du travail. Cette base de données enregistre les renseignements relatifs à l’emploi, comme le poste du travailleur et son secteur d’activité, liés à la demande de prestations. On a créé le SSMP en combinant cette source de renseignements relatifs à l’emploi avec différentes bases de données sur la santé (voir l’infographie).",
        acc2_text2: "Lorsqu’une personne fréquente le système de santé, par exemple, lorsqu’elle consulte un médecin, se rend à l’hôpital ou dans un centre de cancérologie, le diagnostic de maladie qu’elle reçoit est systématiquement enregistré dans diverses bases de données provinciales sur la santé. Pour déterminer les cas de maladies, les données de ces 2,2 millions de travailleurs ont été combinées aux données du registre des tumeurs, aux dossiers hospitaliers, aux dossiers de soins ambulatoires et aux dossiers de facturation des médecins. En combinant les données sur l’emploi et sur le secteur issues des demandes de prestations pour interruption de travail aux données sur les maladies issues des bases de données sur la santé, le SSMP offre une nouvelle approche efficiente pour étudier les maladies professionnelles.",
        acc2_text3: "Pour en savoir plus sur les méthodes, cliquez ici.",
        acc3_title: "Quelles sont les sources de données du SSMP?",
        acc3_sub1: "Base de données sur les demandes de prestations pour interruption de travail de la Commission de la sécurité professionnelle et de l’assurance contre les accidents du travail (CSPAAT)",
        acc3_text1: "La base de données sur les demandes acceptées de prestations pour interruption de travail de la CSPAAT contient des renseignements liés aux demandes de prestations pour les accidents du travail et les maladies professionnelles. Ces renseignements comprennent le poste et le secteur d’activité des travailleurs, et le SSMP compare ces renseignements à ceux des bases de données sur la santé pour examiner les tendances relatives aux maladies professionnelles.",
        acc3_sub2: "BDPI – Base de données sur les personnes inscrites",
        acc3_text2: "La Base de données sur les personnes inscrites (BDPI) du Régime d'assurance-santé de l’Ontario contient des renseignements sur les personnes qui sont inscrites pour bénéficier de services de santé assurés dans la province par l’intermédiaire du Régime d'assurance-santé de l’Ontario. Chaque personne inscrite reçoit un numéro d’assurance-santé unique de 10 chiffres. Pour combiner les dossiers des demandes de prestations pour interruption de travail de la CSPAAT à d’autres bases de données administratives sur la santé, les dossiers de la CSPAAT ont d’abord été combinés à ceux de la BDPI pour récupérer le numéro d’assurance-santé, l’identifiant commun entre ces sources de données, nécessaire pour établir des liens ultérieurement.",
        acc3_sub3: "RCCO – Registre des cas de cancer de l’Ontario",
        acc3_text3: "Cette base de données contient des renseignements sur tous les cas de cancer nouvellement diagnostiqués en Ontario, à l’exception des cancers de la peau sans présence de mélanome. Elle est utilisée pour recenser les cas de cancer dans le SSMP.",
        acc3_sub4: "Base de données des demandes électroniques du Régime d’assurance-santé de l’Ontario",
        acc3_text4: "Cette base de données contient des dossiers soumis par les fournisseurs de soins de santé (essentiellement des médecins) d’établissements de santé autonomes, de soins primaires, de centres universitaires de sciences de la santé et d’hôpitaux aux fins de facturation et de comptabilité. Elle est utilisée pour recenser les cas de maladies non malignes dans le SSMP.",
        acc3_sub5: "SNISA – Système national d’information sur les soins ambulatoires",
        acc3_text5: "Ce système contient des données sur les consultations de soins ambulatoires, notamment les chirurgies ambulatoires, les unités de consultations externes, les cliniques communautaires et les services d’urgence. Elle est utilisée pour recenser les cas de maladies non malignes dans le SSMP.",
        acc3_sub6: "BDCP – Base de données sur les congés des patients",
        acc3_text6: "Cette base de données contient des renseignements sur les congés des patients hospitalisés. Elle est utilisée pour recenser les cas de maladies non malignes dans le SSMP.",
        acc4_title: "Quelles sont les maladies étudiées dans le SSMP?",
        acc4_text1: "Le SSMP est actuellement utilisé pour étudier les liens entre l’emploi/le secteur et 28 sièges du cancer et 9 maladies non malignes. Les définitions de cas standards du registre des tumeurs sont utilisées pour le cancer. Les définitions de cas pour les maladies non malignes ont été élaborées avec un comité consultatif de médecins du travail et d’autres spécialistes, et comprennent les codes de diagnostic pertinents, la fourchette d’âge au cours de laquelle un travailleur est considéré comme présentant un risque, le nombre de consultations du patient nécessaires pour qu’il soit considéré comme un cas et les sources de données (RCCO, BDCP, SNISA, Régime d'assurance-santé de l'Ontario) utilisées pour recenser les cas."

       }
     }
    }
  },
  head: {
    title:{
      inner: 'Overview | Ontario Occupational Disease Statistics'
    }
  },
   computed:{
    setLang : function () {
      var lang = (this.$route.query.locale) ? this.$route.query.locale : 'en';
      return lang;
    }
  }
}
</script>

<style scoped>
p {
  font-size:0.7em;
}
.accordion_background_color {
  background-color:#E7401C;
}
.accordion_header_button {
  background-color:#E7401C;border:none;font-size:1em !important;
}
.accordion_header_button:hover {
  background-color:#E7401C;
}
.accordion_collapse_body {
  background-color:white;color:#444444;margin:0;padding:10px;
}
</style>
